<template>
  <div
    class="page relative rounded-lg cursor-crosshair select-none mb-8"
    :style="drawField ? 'touch-action: none' : ''"
  >
    <img ref="image" loading="lazy" :src="image.url" :width="width" :height="height" @load="onImageLoad" />
    <div class="top-0 bottom-0 left-0 right-0 absolute" @pointerdown="onStartDraw">
      <field-area
        v-for="(item, i) in areas"
        :key="i"
        ref="areaRefs"
        :area="item.area"
        :selected-area-ref="selectedAreaRef"
        :field="item.field"
        :editable="editable"
        :default-field="defaultFields.find(f => f.name === item.field.name)"
        :default-submitters="defaultSubmitters"
        @start-resize="resizeDirection = $event"
        @stop-resize="resizeDirection = null"
        @remove="$emit('remove-area', item.area)"
        @change-selected-area="changeSelectedArea"
      />
      <field-area
        v-if="newArea"
        :is-draw="true"
        :field="{ submitterUuid: selectedSubmitter.uuid, type: (drawField && drawField.type) || defaultFieldType }"
        :area="newArea"
        :selected-area-ref="selectedAreaRef"
        @change-selected-area="changeSelectedArea"
      />
    </div>
    <div
      v-show="resizeDirection || isDrag || showMask || (drawField && isMobile) || fieldsDragFieldRef"
      id="mask"
      ref="mask"
      class="top-0 bottom-0 left-0 right-0 absolute"
      :class="{
        'z-10': !isMobile,
        'cursor-grab': isDrag,
        'cursor-nwse-resize': drawField,
        [resizeDirectionClasses[resizeDirection]]: !!resizeDirectionClasses,
      }"
      @pointermove="onPointermove"
      @pointerdown="onStartDraw"
      @dragover.prevent
      @drop="onDrop"
      @pointerup="onPointerup"
    />
  </div>
</template>

<script>
import FieldArea from './Area.vue';

export default {
  name: 'TemplatePage',
  components: {
    FieldArea,
  },
  inject: ['fieldTypes', 'defaultDrawFieldType', 'fieldsDragFieldRef'],
  props: {
    image: {
      type: Object,
      required: true,
    },
    areas: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    drawFieldType: {
      type: String,
      required: false,
      default: '',
    },
    allowDraw: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedSubmitter: {
      type: Object,
      required: true,
    },
    defaultSubmitters: {
      type: Array,
      required: false,
      default: () => [],
    },
    drawField: {
      type: Object,
      required: false,
      default: null,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDrag: {
      type: Boolean,
      required: false,
      default: false,
    },
    number: {
      type: Number,
      required: true,
    },
    selectedAreaRef: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ['draw', 'drop-field', 'remove-area', 'scroll-to'],
  data() {
    return {
      showMask: false,
      resizeDirection: null,
      newArea: null,
    };
  },
  computed: {
    defaultFieldType() {
      if (this.drawFieldType) {
        return this.drawFieldType;
      }
      if (this.defaultDrawFieldType && this.defaultDrawFieldType !== 'text') {
        return this.defaultDrawFieldType;
      }
      if (this.fieldTypes.length !== 0 && !this.fieldTypes.includes('text')) {
        return this.fieldTypes[0];
      }
      return 'text';
    },
    isMobile() {
      return /android|iphone|ipad/i.test(navigator.userAgent);
    },
    resizeDirectionClasses() {
      return {
        nwse: 'cursor-nwse-resize',
        ew: 'cursor-ew-resize',
      };
    },
    width() {
      return this.image.metadata.width;
    },
    height() {
      return this.image.metadata.height;
    },
  },
  methods: {
    changeSelectedArea(e) {
      this.$emit('change-selected-area', e);
    },
    onImageLoad(e) {
      e.target.setAttribute('width', e.target.naturalWidth);
      e.target.setAttribute('height', e.target.naturalHeight);
    },
    onDrop(e) {
      this.$emit('drop-field', {
        x: e.offsetX,
        y: e.offsetY,
        maskW: this.$refs.mask.clientWidth,
        maskH: this.$refs.mask.clientHeight,
        page: this.number,
      });
    },
    onStartDraw(e) {
      if (!this.allowDraw) {
        return;
      }

      if (this.isMobile && !this.drawField) {
        return;
      }

      if (!this.editable) {
        return;
      }

      this.showMask = true;

      this.$nextTick(() => {
        this.newArea = {
          initialX: e.offsetX / this.$refs.mask.clientWidth,
          initialY: e.offsetY / this.$refs.mask.clientHeight,
          x: e.offsetX / this.$refs.mask.clientWidth,
          y: e.offsetY / this.$refs.mask.clientHeight,
          w: 0,
          h: 0,
        };
      });
    },
    onPointermove(e) {
      if (this.newArea) {
        const dx = e.offsetX / this.$refs.mask.clientWidth - this.newArea.initialX;
        const dy = e.offsetY / this.$refs.mask.clientHeight - this.newArea.initialY;

        if (dx > 0) {
          this.newArea.x = this.newArea.initialX;
        } else {
          this.newArea.x = e.offsetX / this.$refs.mask.clientWidth;
        }

        if (dy > 0) {
          this.newArea.y = this.newArea.initialY;
        } else {
          this.newArea.y = e.offsetY / this.$refs.mask.clientHeight;
        }

        if ((this.drawField?.type || this.drawFieldType) === 'cells') {
          this.newArea.cellW = this.newArea.h * (this.$refs.mask.clientHeight / this.$refs.mask.clientWidth);
        }

        this.newArea.w = Math.abs(dx);
        this.newArea.h = Math.abs(dy);
      }
    },
    onPointerup() {
      if (this.newArea) {
        const area = {
          x: this.newArea.x,
          y: this.newArea.y,
          w: this.newArea.w,
          h: this.newArea.h,
          page: this.number,
        };

        if ('cellW' in this.newArea) {
          area.cellW = this.newArea.cellW;
        }

        this.$emit('draw', area);
      }

      this.showMask = false;
      this.newArea = null;
    },
  },
};
</script>
